


div.header-topbar-account {
    position: absolute;
    bottom: 21px;
    right: 40px;
}

div.header-topbar-account>a {
    text-align: center;
    color: black;
    font-size: 19px;
    display: inline-block;
    border-radius: 3px;
    font-weight: 800;
    box-sizing: content-box;
    background-color: #ffd452;
    border: solid 3px #ffd452;
    width: 116px;
    height: 31px;
}

div.header-topbar-account>a span {
    vertical-align: middle;
}

div.header-topbar-account>a.header-topbar-account-register {
    background-color: white;
}

div.header-topbar-account>a.header-topbar-account-login {
    margin-left: 20px;
}

div.header-topbar-account>a.header-topbar-account-membercenter {
    position: relative;
    top: 5px;
}

div.header-topbar-account>a.header-topbar-account-logout {
    margin-left: 20px;
    position: relative;
    top: 5px;
}

div.header-topbar-account-loginFormWrapper {
    position: absolute;
    z-index: 100;
    /* 要盖过modal出现时候99的背景颜色 */
    top: 23px;
    left: -40px;
    height: 440px;
    width: 320px;
    overflow: hidden;
    display: none;
    background: url("/images/loginForm-bg.png") no-repeat;
    background-size: 352px 490px;
}

form.header-topbar-account-loginForm {
    position: absolute;
    margin-left: 60px;
    height: 440px;
    width: 270px;
    float: left;
}

form.header-topbar-account-loginForm label {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

form.header-topbar-account-loginForm label.mod-40p {
    width: 40%;
}

form.header-topbar-account-loginForm label.rememberBox span {
    position: absolute;
    top: 0;
    left: 20px;
}

form.header-topbar-account-loginForm label.rememberBox input[name="remember"] {
    position: absolute;
    top: -10px;
    left: 0;
}

form.header-topbar-account-loginForm label>input {
    width: 230px;
    height: 37px;
    border: solid 2px #ffd452;
    border-radius: 3px;
    outline-color: #ffd452;
}
form.header-topbar-account-loginForm label>input[name="captcha"] {
    width: 150px;
}

form.header-topbar-account-loginForm input[type="submit"] {
    background-color: #ffd452;
    font-size: 18px;
    font-weight: bold;
}

form.header-topbar-account-loginForm label span.field_title {
    background-color: #FFFFFF;
    left: 20px;
}

form.header-topbar-account-loginForm input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
}

form.header-topbar-account-loginForm>h3 {
    float: left;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: -23px;
    width: 278px;
    border-bottom: solid 1px #eeeeee;
    padding-bottom: 13px;
}

form.header-topbar-account-loginForm>h3>a {
    background: url("/images/logo-noshadow.png") no-repeat;
    text-indent: -1000em;
    width: 161px;
    background-size: 161px 39px;
    height: 37px;
    display: inline-block;
    margin-top: 8px;
}

form.header-topbar-account-loginForm h3 a {
    border: none;
}

form.header-topbar-account-loginForm>a.loginForm-retrieveAccount {
    float: left;
    width: 60%;
    color: #aaaaaa;
    text-decoration: underline;
    margin-bottom: 5px;
}

span.icon {
    background: url("/images/registerForm-icons.png") no-repeat;
    background-size: 143px 107px;
    display: block;
    position: absolute;
}

span.icon.icon-username {
    width: 30px;
    height: 27px;
    background-position: -34px -75px;
    left: 190px;
    top: 5px;
}

span.icon.icon-psw {
    width: 23px;
    height: 23px;
    background-position: -115px -42px;
    left: 195px;
    top: 8px;
}

form.header-topbar-account-loginForm label span.invalid_msg {
    left: 14px;
    top: 36px;
}

form#loginForm span.loader {
    top: 353px;
    border-top-color: #ffd452;
}

div.header-topbar-account>a.header-topbar-account-balance {
    height: 31px;
    margin-left: 20px;
    background-color: white;
    pointer-events: none;
    padding: 0 5px;
    border: none;
    vertical-align: middle;
    width: initial;
}

@font-face {
    font-family: "jackpotFont";
    src: url("/fonts/SHOWG.TTF");
}

a.header-topbar-account-balance>span.text {
    color: red;
    font-family: "jackpotFont";
}

a.header-topbar-account-balance>span.icon-money {
    display: inline-block;
    background: url("/images/icons/much-money.png") no-repeat;
    background-size: 66px 50px;
    width: 66px;
    height: 50px;
    vertical-align: top;
    margin-top: -8px;
}

p.header-topbar-account-welcomeMsg {
    font-weight: bold;
    text-align: right;
    margin-bottom: 3px;
    position: relative;
}

p.header-topbar-account-welcomeMsg * {
    vertical-align: middle;
}

p.header-topbar-account-welcomeMsg a.msg-icon {
    background-image: url("/images/sprites/account-icons.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 22px;
    background-position: -104px -51px;
    cursor: pointer;
    transform: scale(0.9, 0.9);
}

p.header-topbar-account-welcomeMsg span.badge {
    position: absolute;
    right: -10px;
    top: -3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    border-radius: 50%;
    background: #E30B20;
    text-align: center;
    padding: 0;
    pointer-events: none;
}